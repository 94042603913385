<template>
  <div class="looks-list" :class="[classGridSystem]">
    <b-row no-gutters>
      <b-col v-for="(child, index) in childData" :key="child.id" lg="3" :id="'look-' + (index + 1)" class="wrapp-single-item">
        <look-tile :child="child" :listPosition="index += 1" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LookTile from './LookTile'

export default {
  name: 'LooksList',
  props: {
    childData: {
      type: Array,
      required: true
    },
    gridLayout: {
      type: Number,
      required: false
    }
  },
  computed: {
    classGridSystem () {
      return 'column-' + (12 / this.gridLayout)
    }
  },
  components: {
    LookTile
  }
}
</script>
