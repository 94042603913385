<template>
  <article class="looks-product">
    <div class="looks-product__media">
      <image-gallery
      :img-path="imgObj.src"
      :src="imgObj.loading"
      size="MD"
      :styleImage="{ maxWidth: '100%', height: 'auto' }"
      :changeAble="false"/>
    </div>
    <div class="looks-product__info">
      <div class="list-position">
        <span class="list-position__value">{{ listPosition }}</span>
      </div>
      <div class="single-look-cta">
        <router-link :to="'listing' | localizedByNameCategories(child.url_path, child, $store, $router)" class="ff-link">{{ $t('shop-now') }}</router-link>
      </div>
    </div>
  </article>
</template>

<script>
import _ from 'lodash'
import Config from '@/config'

import ImageGallery from '@/components/Product/Image.vue'

export default {
  name: 'LooksTile',
  props: {
    child: {
      type: Object,
      required: true
    },
    listPosition: {
      type: Number,
      required: true
    }
  },
  computed: {
    categoryImage () {
      // to do. use this process.env.VUE_APP_MAGENTO
      const baseUrl = process.env.VUE_APP_MAGENTO
      let catUrl = ''
      if (this.child && this.child.custom_attributes) {
        let match = _.find(this.child.custom_attributes, ['attribute_code', 'image'])
        const { width, height } = Config.Theme.defaultOpt.imageCrop
        if (match) {
          catUrl = baseUrl + match.value
          catUrl = `${catUrl}?fit=crop&w=${width}&h=${height}`
          return catUrl
        }
      }
      return catUrl
    },
    imgObj () {
      return {
        src: this.categoryImage,
        error: require('@/assets/image/dump.png'),
        loading: require('@/assets/image/dump.png')
      }
    }
  },
  components: {
    ImageGallery
  }
}
</script>
