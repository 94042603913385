<template>
  <div class="look-single-navigation">
    <div class="aqz-container">
      <b-row no-gutters class="align-items-center">
        <b-col cols="6" lg="2" class="left-column">
          <div class="navigation-column back-to-look" v-show="navigation.parentUrl">
            <router-link
              :to="'listing' | localizedByNameCategories(navigation.parentUrl, null, $store, $router)"
              class="btn btn-icon-text"
            >
              <span class="icon">
                <i class="ff-icon ff-icon-40-layout-multiple"></i>
              </span>
              <span class="text ml-3" v-t="'all-looks'"></span>
            </router-link>
          </div>
        </b-col>
        <b-col md="12" lg class="middle-column no-mb-margin l-t">
          <div class="navigation-column middle-navigation">
            <div class="wrapp-content">
              <router-link
                :to="'listing' | localizedByNameCategories(navigation.prevUrl, null, $store, $router)"
                class="btn btn-icon"
                :class="{'invisible': !navigation.prevUrl}"
              >
                <span class="icon">
                  <i class="ff-icon ff-icon-26-arrow-left"></i>
                </span>
              </router-link>
              <h3 class="title">{{ category.name }}</h3>
              <router-link
                :to="'listing' | localizedByNameCategories(navigation.nextUrl, null, $store, $router)"
                class="btn btn-icon"
                :class="{'invisible': !navigation.nextUrl}"
              >
                <span class="icon">
                  <i class="ff-icon ff-icon-29-arrow-right"></i>
                </span>
              </router-link>
            </div>
          </div>
        </b-col>
        <b-col cols="6" lg="2" class="right-column">
          <div class="navigation-column iteraction-icons">
            <ul class="nav ml-auto justify-content-end">
              <li>
                <a
                  href="#"
                  class="ff-link ff-link-icon"
                  :class="{active: sharingTip}"
                  @click.prevent="opensharingTip($event, !sharingTip)"
                >
                  <i class="ff-icon ff-icon-13-share"></i>
                </a>
              </li>
              <li>
                <!-- <add-to-wishlist
                :disabled="disabledAddToCart"
                :product="product"
                :productSelected="productSelected"
                :variant="getVariantsSelected" />-->
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </div>
    <no-ssr>
      <social-share
      :value="sharingTip"
      :title="category.name"
      :description="category.description || ''"
      v-on:close="opensharingTip"
      ></social-share>
    </no-ssr>
  </div>
</template>

<script>
import _ from 'lodash'
import SocialShare from '@/theme/components/Modal/SocialShare'
import AddToWishlist from '@/theme/components/AddToWishlist/AddToWishlist'

export default {
  data () {
    return {
      sharingTip: false
    }
  },
  computed: {
    category () {
      return this.$store.state.category.current
    },
    categoryImage () {
      // to do. use this process.env.VUE_APP_MAGENTO
      let catUrl = ''
      if (this.category && this.category.custom_attributes) {
        let match = _.find(this.category.custom_attributes, ['attribute_code', 'image'])
        if (match) {
          catUrl = process.env.VUE_APP_MAGENTO + match.value
          return catUrl
        }
      }
      return catUrl
    },
    imgObj () {
      return {
        src: this.categoryImage,
        error: require('@/assets/image/dump.png'),
        loading: require('@/assets/image/dump.png')
      }
    },
    navigation () {
      return this.$store.getters['looksBook/getNavigation']
    }
  },
  methods: {
    opensharingTip ($event, data) {
      this.sharingTip = data
    }
  },
  components: {
    AddToWishlist,
    SocialShare
  }
}
</script>
